import { render, staticRenderFns } from "./form-number-field.vue?vue&type=template&id=a67d3eb0&scoped=true&"
import script from "./form-number-field.vue?vue&type=script&lang=js&"
export * from "./form-number-field.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a67d3eb0",
  null
  
)

export default component.exports