<template>
<fragment>
  <div class="flex justify-start">
    <label :class="['block text-sm font-medium leading-5', required && validating && noneStr(value) ? 'text-red-600': 'text-gray-700']" v-html="label">{{ label }}</label>
    <span class="text-sm ml-2 leading-5 text-gray-300 font-light hover:text-green-500 cursor-pointer" id="email-optional">
      <i v-if="info" v-tooltip.top-center="info"  class="fas fa-info-circle"></i>
    </span>
  </div>
  <div class="mt-1 relative rounded-sm shadow-sm w-full">
    <t-select
        :value="value"
        v-on:input="updateValue($event)"
        :options="options"
        value-attribute="id"
        text-attribute="name"
    ></t-select>
  </div>
</fragment>
</template>

<script>
import {noneStr} from "@/common";

export default {
  name: "form-text-field",
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    id: {
      type: String
    },
    info: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: [Number, String]
    },
    validating: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    noneStr(v) {
      return noneStr(v);
    },
    updateValue: function (value) {
      this.$emit('input', parseInt(value))
    }
  }
}
</script>

<style scoped>

</style>

