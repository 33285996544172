<template>
  <div class="flex flex-col w-full">
        <div class="w-full bg-gray-50 text-gray-600">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Type</div>
            <div class="text-xs">Basic info like Make, Model, body type, colors and physical aspects of the vehicle</div>
          </div>
          <div class="mx-6 mt-2 p-2 pb-4 bg-white rounded-lg flex flex-col">
            <div class="flex flex-row w-full pr-3">
              <div class="ml-2 mt-2 w-1/2">
                <div class="flex justify-start">
                  <label :class="[validating && (
                      (!manual() && (noneStr(lead().condition.car.makeText) || noneStr(lead().condition.car.makeText))) ||
                      (manual() && (noneInt(makeId) || noneInt(modelId)))
                  ) ? 'text-red-600': 'text-gray-700', 'block text-sm font-medium leading-5']">Brand and Model</label>
                </div>
                <div v-if="manual()" class="w-full mt-1 flex flex-row">
                  <div class="relative w-1/2">
                    <t-rich-select
                        :options="this.general.makes"
                        v-model="makeId"
                        placeholder="Search for a brand"
                        value-attribute="id"
                        text-attribute="name"
                        :variant="(validating && noneInt(lead().condition.car.makeId)) ? 'danger': null"
                    ></t-rich-select>
                  </div>
                  <div class="ml-4 relative w-1/2">
                    <t-rich-select
                        :options="models"
                        v-model="modelId"
                        placeholder="Search for a model"
                        value-attribute="id"
                        text-attribute="name"
                        :variant="(validating && noneInt(lead().condition.car.modelId)) ? 'danger': null"
                    ></t-rich-select>
                  </div>
                </div>
                <div v-else class="mt-1 relative rounded-sm shadow-sm w-full flex flex-row">
                  <input
                      :value="(nil(lead().condition.car.makeText) + ' ' + nil(lead().condition.car.modelText)).trim()"
                      type="text"
                      :class="[validating && (noneStr(lead().condition.car.makeText) || noneStr(lead().condition.car.makeText)) ? 'border-red-600': '', 'block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 border-l-4 border-gray-300 rounded']" readonly>
                  <button v-on:click="triggerManual" v-tooltip.top-center="'Manual configuration'"  class="-ml-1 relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-r-md text-gray-400 bg-gray-50 hover:border-blue-500 hover:text-blue-500 hover:bg-blue-100 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                    <i class="far fa-hand-paper"></i>
                  </button>
                </div>
              </div>
              <div class="ml-4 mt-2 w-1/2">
                <form-text-field v-model="lead().condition.car.versionText" label="Version" :validating="validating" :required="true"  info="Version like business line, ..." />
              </div>
            </div>
            <div class="flex flex-row mt-2 pb-2 pr-3">
              <div class="ml-2 mt-2 w-1/4">
                <form-select-field v-model="lead().condition.car.colorExteriorId" :options="general.colorsexterior" label="Exterior color" :validating="validating" :required="true"  />
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <form-text-field v-model="lead().condition.car.colorExteriorText" label="Specific" :validating="validating" :required="false"  info="Manufacturer color" />
              </div>
              <div class="ml-4 mt-2 w-1/4"></div>
              <div class="ml-4 mt-2 w-1/4"></div>
            </div>
            <div class="flex flex-row mt-2 pb-2 pr-3">
              <div class="ml-2 mt-2 w-1/4">
                <form-select-field v-model="lead().condition.car.colorInteriorId" :options="general.colorsinterior" label="Interior color" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <form-text-field v-model="lead().condition.car.colorInteriorText" label="Specific" :validating="validating" :required="false"  info="Manufacturer color" />
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <form-select-field v-model="lead().condition.car.coveringInteriorId" :options="general.coveringsinterior" label="Covering" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/4"></div>
            </div>
          </div>
        </div>
        <div class="w-full bg-gray-50 text-gray-600">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Form</div>
            <div class="text-xs">Body, doors, places, wheel basis, weight capacity</div>
          </div>
          <div class="mx-6 mt-2 p-2 pb-4 bg-white rounded-lg flex flex-col">
            <div class="flex flex-row pr-3 w-full">
              <div class="ml-2 mt-2 w-1/4">
                <form-select-field v-model="lead().condition.car.bodyTypeId" :options="general.bodies" label="Body" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <form-select-field v-model="lead().condition.car.doorCount" :options="general.doors" label="Doors" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/4">
                <form-select-field v-model="lead().condition.car.seatCount" :options="general.seats" label="Seats" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <form-number-field v-model="lead().condition.car.wheelbase" label="Wheel basis mm." :validating="validating" :required="false" />
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <form-number-field v-model="lead().condition.car.weightCapacity" label="Weight capacity kg." :validating="validating" :required="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full bg-gray-50 text-gray-600 pb-4">
          <div class="pl-10 pt-3">
            <div class="text-xl font-bold">Engine</div>
            <div class="text-xs">Fuel, gearbox power, emission, capacity and more.</div>
          </div>
          <div class="mx-6 mt-2 p-2 pb-4 bg-white rounded-lg flex flex-col">
            <div class="flex flex-row pr-3 w-full">
              <div class="ml-2 mt-2 w-1/5">
                <form-select-field v-model="lead().condition.car.fuelTypeId" :options="general.fuels" label="Fuel" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <form-select-field v-model="lead().condition.car.transmissionId" :options="general.gearboxes" label="Gearbox" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/5">
                <form-select-field v-model="lead().condition.car.driveTypeId" :options="general.wheelformulas" label="Drive" :validating="validating" :required="false"  />
              </div>
            </div>
            <div class="flex flex-row mt-2 pr-3 w-full">
              <div class="ml-2 mt-2 w-1/6">
                <form-number-field v-model="powerHp" label="Power HP" :validating="validating" :required="false" />
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <form-number-field v-model="powerKw" label="Power KW" :validating="validating" :required="false" />
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <form-number-field v-model="lead().condition.car.powerFiscal" label="Fiscal" :validating="validating" :required="false" />
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <form-select-field v-model="lead().condition.car.emissionNormId" :options="general.emissionnorms" label="Norm" :validating="validating" :required="false"  />
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <form-number-field v-model="lead().condition.car.emissionCO2" label="CO<sub>2</sub> gr/km." :validating="validating" :required="false" />
              </div>
              <div class="ml-4 mt-2 w-1/6">
                <form-select-field v-model="lead().condition.car.emissionTypeId" :options="general.emissiontypes" label="CO<sub>2</sub> type" :validating="validating" :required="false"  />
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import FormTextField from "@/components/form-text-field";
import FormNumberField from "@/components/form-number-field";
import FormSelectField from "@/components/form-select-field";
import {nil} from "@/common";

export default {
  name: "Vehicle",
  components: {FormTextField, FormNumberField, FormSelectField},
  data() {
    return {
      loading: true,
      recalculating: false,
      general: {},
      makeId: this.lead().condition.car.makeId,
      modelId: this.lead().condition.car.modelId,
      powerHp: this.lead().condition.car.powerHp,
      powerKw: this.lead().condition.car.powerKw,
      generalBase: {},
      models: [],
      makeModelOptions: [],
      validating: false
    }
  },
  methods: {
    lead() {
      return this.$store.getters['seller/lead'];
    },
    manual() {
      if(nil(this.lead().condition.car.makeText)!=="" && (this.lead().condition.car.makeId===undefined||this.lead().condition.car.makeId==null||this.lead().condition.car.makeId===0)) {
        return false;
      }
      return true;
    },
    triggerManual() {
      this.lead().condition.car.makeId = -1;
    },
    setFirstRegistration(date) {
      this.lead().condition.car.firstRegistrationDate = date.toDate();
    },
    onChangeMakeModel(value) {
      if(value[0] === "0") return;
      if(value.length>=1) {
        const make = _.head(_.filter(this.general.makes, (make) => { return make.key == value[0] }));
        this.lead().condition.car.makeText = make.name;
      }
      if(value.length>=2) {
        const model = _.head(_.filter(this.general.models, (model) => { return model.key == value[1] }));
        this.lead().condition.car.modelText = model.name;
      }
    },
    signalLoaded() {
      this.fillFields();
      if(this.lead().condition.car.makeId) this.models = _.filter(this.general.models, (m) => m.make_id == this.lead().condition.car.makeId);
      if(nil(this.lead().condition.car.colorExteriorId).length === 8 || this.lead().condition.car.colorExteriorId===0) { this.general.colorsexterior.unshift({id: 0, key: '0', name: this.lead().condition.car.colorExteriorText }); this.lead().condition.car.colorExteriorId = 0; }
      if(nil(this.lead().condition.car.colorInteriorId).length === 8 || this.lead().condition.car.colorInteriorId===0) { this.general.colorsinterior.unshift({id: 0, key: '0', name: this.lead().condition.car.colorInteriorText }); this.lead().condition.car.colorInteriorId = 0; }
      if(nil(this.lead().condition.car.coveringInteriorId).length === 8 || this.lead().condition.car.coveringInteriorId===0) { this.general.coveringsinterior.unshift({id: 0, key: '0', name: this.lead().condition.car.coveringInteriorText }); this.lead().condition.car.coveringInteriorId = 0; }
      if(nil(this.lead().condition.car.bodyTypeId).length === 8 || this.lead().condition.car.bodyTypeId===0) { this.general.bodies.unshift({id: 0, key: '0', name: this.lead().condition.car.bodyTypeText }); this.lead().condition.car.bodyTypeId = 0; }
      if(nil(this.lead().condition.car.fuelTypeId).length === 8 || this.lead().condition.car.fuelTypeId===0) { this.general.fuels.unshift({id: 0, key: '0', name: this.lead().condition.car.fuelTypeText }); this.lead().condition.car.fuelTypeId = 0; }
      if(nil(this.lead().condition.car.transmissionId).length === 8 || this.lead().condition.car.transmissionId===0) { this.general.gearboxes.unshift({id: 0, key: '0', name: this.lead().condition.car.transmissionText }); this.lead().condition.car.transmissionId = 0; }
      if(nil(this.lead().condition.car.driveTypeId).length === 8 || this.lead().condition.car.driveTypeId===0) { this.general.wheelformulas.unshift({id: 0, key: '0', name: this.lead().condition.car.driveTypeText }); this.lead().condition.car.driveTypeId = 0; }
      if(nil(this.lead().condition.car.emissionNormId).length === 8 || this.lead().condition.car.emissionNormId===0) { this.general.emissionnorms.unshift({id: 0, key: '0', name: this.lead().condition.car.emissionNormText }); this.lead().condition.car.emissionNormId = 0; }
      this.loading = false;
    },
    fillFields() {
      this.general = _.cloneDeep(this.generalBase);
      let models = [];
      _.each(this.general.makes, (make) => {
        let mk = {
          'id': make.id,
          'value': make.key,
          'label': make.name,
          'children': []
        };
        _.each(_.filter(this.general.models, (model) => { return model.make_id === mk.id }), (model) => {
          mk.children.push({
            'value': model.key,
            'label': model.name
          });
        });
        models.push(mk);
      });
      this.makeModelOptions = models;
    },
    selectMenu(menu) {
      this.$emit('select-detail-menu', menu);
    }
  },
  watch: {
      makeId: function (newValue, oldValue) {
        if(newValue!==oldValue) {
          this.lead().condition.car.makeId = newValue;
          this.modelId = null;
        }
        let models = _.filter(this.general.models, (m) => m.make_id == newValue);
        this.models = models;
      },
      modelId: function (newValue, oldValue) { if(newValue!==oldValue) this.lead().condition.car.modelId = newValue; },
    powerHp: function (newValue, oldValue) {
      if(this.recalculating) {
        this.recalculating = false;
        return;
      }
      if(newValue!==oldValue) {
        this.recalculating = true;
        this.lead().condition.car.powerHp = parseInt(newValue);
        this.powerKw = parseInt(parseFloat(newValue) * 0.745699872);
        this.lead().condition.car.powerKw = this.powerKw;
      }
    },
    powerKw: function (newValue, oldValue) {
      if(this.recalculating) {
        this.recalculating = false;
        return;
      }
      if(newValue!==oldValue) {
        this.recalculating = true;
        this.lead().condition.car.powerKw = newValue;
        this.powerHp = parseInt(parseFloat(newValue) / 0.745699872);
        this.lead().condition.car.powerHp = this.powerHp;
      }
    },

  },
  created() {
    if(this.$store.getters['tradein/vehicleItems']!==null&&this.$store.getters['tradein/vehicleItems']!==undefined) {
      this.generalBase = this.$store.getters['tradein/vehicleItems'];
      this.signalLoaded();
    } else {
      axios.get('/manager/fleet/general/all/').then(response => {
        if (response.status === 200) this.$store.commit('tradein/setVehicleItems', response.data);
        this.generalBase = response.data;
        this.signalLoaded();
      });
    }
  },
}
</script>

<style scoped>

</style>